import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const Faqs = ({ data }) => (
  <>
    {data.map(({ node }) => (
      <Faq
        key={node.id}
        id={node.id}
        question={node.frontmatter.Question}
        answer={node.frontmatter.answer}
      />
    ))}
  </>
);

const Faq = ({ id, question, answer }) => {
  const [active, setActive] = useState(false);
  const classes = `faq_row${active ? " faq_active" : ""}`;

  return (
    <div
      key={id}
      className={classes}
      onClick={() => setActive(!active)}
      aria-hidden="true"
    >
      <p className="faq_question">{question}</p>
      <div
        className="faq_answer"
        style={{
          maxHeight: active ? "720px" : 0,
        }}
      >
        <div style={{ paddingBottom: "30px" }}>
          <ReactMarkdown>{answer}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

const HomeFaqs = ({ title = "FAQs", templateKey = "faqs" }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            frontmatter {
              Question
              answer
              templateKey
              order
            }
          }
        }
      }
    }
  `);

  // Filter and sort the data based on the templateKey prop and order field
  const filteredData = data.allMarkdownRemark.edges
    .filter((edge) => edge.node.frontmatter.templateKey === templateKey)
    .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order);
  console.log(filteredData);
  return (
    <div id="faqs" className="faq">
      <div
        className="content faqs-c"
        style={
          templateKey === "auditcover-faqs"
            ? { marginBottom: "100px" }
            : undefined
        }
      >
        <h2>{title}</h2>
        <div className="faq_holder">
          <Faqs data={filteredData} />
        </div>
      </div>
    </div>
  );
};

export default HomeFaqs;
