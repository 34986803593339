import React from "react";

const FaqBanner = () => {
  return (
    <div className="blogs">
      <div className="content needs-c">
        <div className="left-right-blog">
          <div className="left-right-half">
            <h4 className="blogs-heading pre-line">
              Frequently Asked Questions
            </h4>
          </div>
          <div className="left-right-half"></div>
        </div>
      </div>
    </div>
  );
};

export default FaqBanner;
