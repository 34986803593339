import * as React from "react";

import Layout from "../components/Layout";
import FaqBanner from "../components/FAQBanner";
import HomeFaqs from "../components/HomeFaqs";

export default class FaqPage extends React.Component {
  render() {
    return (
      <Layout
        noPadding
        isWhite={true}
        darkLogo={true}
        isDisplayGetQuoteBtn={false}
      >
        <FaqBanner />

        <section className="section">
          <div className="container">
            <div className="content">
              <HomeFaqs title={`What's covered`} templateKey="covered-faqs" />
              <HomeFaqs title={`Partner FAQs`} templateKey="partner-faqs" />
              <HomeFaqs
                title={`About AuditCover`}
                templateKey="auditcover-faqs"
              />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
